import classes from './Quality.module.css';
import pointImg from './points.png';

const Quality = () => {
    return (
        <div className={classes.hold}>


            <div className={classes.divHold}>
                <div className={classes.left}>
                    <p className={classes.head} >Quality Metrics</p>

                    <ul>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Trained Facility Management Service Personal</span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Customized pricing and service structure</span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Compliant to every legal verification and documentation</span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Qualified and skilled workforce</span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Widest service network</span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Shortest Turn Around Time (TAT)</span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Alert and ability to handle any emergency</span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Well versed with latest equipment and technology</span></li>
                    </ul>
                </div>

                <div className={classes.right}>
                    <p className={classes.head} >Our Priorities</p>
                    <ul>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Extensive team of trained and experienced professionals</span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Round the clock security so that you get 24*7 protection.</span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Huge network of reserved staff to replace the permanent staff in case of emergency </span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>Timely inspections and Communication training.</span></li>
                        <li className={classes.lin}>
                            <img src={pointImg} className={classes.point}></img>
                            <span className={classes.txt}>We try to win 100% Clients satisfaction.</span></li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default Quality;