import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';



import HomePage from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import Root from './Pages/Root';

import HousePage from './Pages/HousePage';
import SecurityPage from './Pages/SecurityPage';
import FacilityPage from './Pages/FacilityPage';
import Career from './components/career/Career';

import SecurityT from './Pages/TrainingPages/SecurityT';
import FacilityT from './Pages/TrainingPages/FacilityT';
import HouseT from './Pages/TrainingPages/HouseT';
import CompanyProfile from './Pages/CompanyProfile';



const router = createBrowserRouter([{
  path: '/',
  element: <Root></Root>,

  children: [
    { path: '/', element: <HomePage></HomePage> },
    { path: '/about', element: <AboutPage></AboutPage> },
    { path: '/contact', element: <ContactPage></ContactPage> },

    { path: '/security', element: <SecurityPage></SecurityPage> },
    { path: '/Housekeeping', element: <HousePage></HousePage> },
    { path: '/FacilityManagement', element: <FacilityPage></FacilityPage> },
    { path: '/career', element: <Career></Career> },

    { path: '/securitytraining', element: <SecurityT></SecurityT> },
    { path: '/Housekeepingtraining', element: <HouseT></HouseT> },
    { path: '/FacilityManagementtraining', element: <FacilityT></FacilityT> },
    { path: '/profile', element: <CompanyProfile></CompanyProfile> },


  ]
}]);



const App = () => {
  return <RouterProvider router={router}></RouterProvider>

};

export default App;