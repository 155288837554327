
import { logDOM } from '@testing-library/react';
import React from 'react'
import logo from '../Navbar/logo.png';
import { FloatingWhatsApp } from 'react-floating-whatsapp'


//avatar={logo}
export default function NewWhatsapp() {
    return (
        <div className="App2">
            <FloatingWhatsApp
                phoneNumber="+91 9822324473"
                accountName="Securise Facility Management Services"
                avatar={logo}
                chatMessage="Hello"
                allowEsc
                allowClickAway
                notification
                notificationSound
            />
        </div>
    );
}