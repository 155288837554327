import classes from './Contact.module.css';

const Contact = () => {
    const clkHnd = () => {
        alert('Form Submitted, THANKYOU')
    };

    return (
        <div className={classes.hold}>
            <p className={classes.head}>For Enquiry Of Services</p>
            <p className={classes.head2}>Please get in touch if you have any queries regarding services we provide. Thankyou!</p>

            <div className={classes.formHold}>
                <form action='https://formsubmit.co/admin@securise.in' method='POST'>
                    <input className={classes.in} name='name' type='text' placeholder='Name'></input>
                    <input className={classes.in} name='mobile number' type='text' placeholder='Mobile Number'></input>
                    <select className={classes.in} name='service'>
                        <option>Choose Service</option>
                        <option>Security Service</option>
                        <option>Facility Management</option>
                        <option>Housekeeping Service</option>
                    </select><br></br>
                    <input type="hidden" name="_captcha" value='false'></input>
                    <button onClick={clkHnd} className={classes.btn} type='submit'>Enquire Now</button>
                </form>
            </div>
        </div>
    )
}

export default Contact;