import { useEffect } from 'react';
import classes from './CompanyProfile.module.css';
import { Document, Page } from 'react-pdf';
import profilepdf from './CompayProfile.pdf';
import ISOpdf from './ISO.pdf';

const CompanyProfile = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.hold}>
            <p className={classes.profile}>Download CompanyProfile</p>

            <embed src={profilepdf} width="90%" height="600"
                type="application/pdf"></embed>

            <div className={classes.isoHold}>
                <p className={classes.isoHead}>ISO Certificate</p>
                <embed src={ISOpdf} width="90%" height="600"
                    type="application/pdf"></embed>
            </div>
        </div>
    )
}

export default CompanyProfile;