import { useEffect } from 'react';
import classes from './House.module.css';

const House = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.hold}>
            <div className={classes.namehold}>
                <div className={classes.inCard}><p className={classes.name}>Housekeeping Services</p></div>
            </div>

            <div className={classes.cardhold}>
                <div className={classes.card}>
                    <div className={classes.inCard}> <p className={classes.txt}>Facade Cleaning</p></div>
                </div>

                <div className={classes.card2}>
                    <div className={classes.inCard}><p className={classes.txt}>Upholstery Cleaning</p></div>
                </div>

                <div className={classes.card3}>
                    <div className={classes.inCard}><p className={classes.txt}>Cleaning </p></div>
                </div>

                <div className={classes.card4}>
                    <div className={classes.inCard}><p className={classes.txt}>Carpet Cleaning </p></div>
                </div>

                <div className={classes.card5}>
                    <div className={classes.inCard}><p className={classes.txt}>Tidying </p></div>
                </div>

                <div className={classes.card6}>
                    <div className={classes.inCard}><p className={classes.txt}>Waste Management </p></div>
                </div>

                <div className={classes.card7}>
                    <div className={classes.inCard}><p className={classes.txt}>Mechanised Housekeeping </p></div>
                </div>
            </div>
        </div>
    )
}

export default House