import { useEffect } from 'react';
import classes from './SecurityT.module.css';
import sec1 from '../../components/Home/asset/new/secT1.jpg';
import img2 from '../asset/SecT2.jpg';

const SecurityT = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.hold}>
            <div className={classes.namehold}>
                <p className={classes.name}>Security Training</p>
            </div>
            <div className={classes.divsHold}>
                <div className={classes.lft}>
                    <img src={sec1} className={classes.iconSecurity}></img>
                    <img src={img2} className={classes.iconSecurity}></img>
                </div>
                <div className={classes.right}>
                    <div className={classes.card}>
                        <p className={classes.head}>SUPERVISORY TRAINING</p>
                        <p className={classes.txt}>We provide supervisory training to those security personnel found capable of supervisory duties. This training module covers supervision, leadership, human Relationship Management, Substance Abuse detection and mentoring, emergency response, etc.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>PRE-JOB TRAINING</p>
                        <p className={classes.txt}>A profession that encompasses multiple disciplines to ensure functionality of the built environment by integrating people, place, process and technology.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>REFRESHER TRAINING</p>
                        <p className={classes.txt}>We give mandatory refresher training to all our security personnel twice a year. More frequent training is provided as per the client's needs and the performance of each specific individual.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>POSITIVE ATTITUDE</p>
                        <p className={classes.txt}>SECURISE SERVICES holds many sessions to develop a positive attitude in its security personnel to cope with difficult real-life situations without bitterness or any negativity.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>ENVIRONMENTAL TRAINING</p>
                        <p className={classes.txt}>SECURISE SERVICES also trains its security personnel to be eco-friendly in their outlook and care for the environment.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecurityT;