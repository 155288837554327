import React, { useState } from "react";
import { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Navi.css";
import logo from './logo.png';
import plusImg from './plus.png';


function Navi() {
    /*Navbar */
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);



    return (
        <>
            <header>
                <nav className="navbar">
                    <div className="nav-container">
                        <NavLink exact to="/" className="nav-logo">
                            <img src={logo} alt="securise" ></img>
                        </NavLink>

                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/about"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    About Us
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <div class="dropdown">
                                    <li className="act2" ><NavLink className="act2">Services</NavLink> <img src={plusImg} className='plus'></img></li>
                                    <div class="dropdown-content">
                                        <NavLink to='/security' activeClassName="active" className="nav-links" onClick={handleClick}>Security</NavLink>
                                        <NavLink to='/Housekeeping' activeClassName="active" className="nav-links" onClick={handleClick}>HouseKeeping</NavLink>
                                        <NavLink to='/FacilityManagement' activeClassName="active" className="nav-links" onClick={handleClick}>Facility Management</NavLink>

                                    </div>
                                </div>

                            </li>

                            <li className="nav-item">
                                <div className="dropdown ">
                                    <li className="  act2"><NavLink className="act2" >Training</NavLink><img src={plusImg} className='plus'></img></li>
                                    <div className="dropdown-content">
                                        <NavLink to='/securitytraining' className="nav-links" onClick={handleClick}>Security</NavLink>
                                        <NavLink to='/Housekeepingtraining' activeClassName="active" className="nav-links" onClick={handleClick}>HouseKeeping</NavLink>
                                        <NavLink to='/FacilityManagementtraining' activeClassName="active" className="nav-links" onClick={handleClick}>Facility Management</NavLink>

                                    </div>
                                </div>

                            </li>

                            <li className="nav-item"><NavLink to='/career' activeClassName="active" className="nav-links" onClick={handleClick}>Career</NavLink></li>




                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/contact"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    Contact Us
                                </NavLink>
                            </li>
                            <li className="nav-item"><NavLink to='/profile' onClick={handleClick} activeClassName="active" className="nav-links" span={true} smooth={true}>Company Profile</NavLink></li>

                        </ul>
                        <div className="nav-icon" onClick={handleClick}>
                            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
                        </div>
                    </div>
                </nav>

            </header>

        </>
    );
}

export default Navi;