import { useEffect } from 'react';
import classes from './SecurityT.module.css';
import facility from './Facility-Management-Concept.png';
import img2 from '../asset/facT1.jpg';

const FacilityT = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.hold}>
            <div className={classes.namehold}>
                <p className={classes.name}>Facility Management Training</p>
            </div>
            <div className={classes.divsHold}>
                <div className={classes.lft}>
                    <img src={facility} className={classes.iconfacility}></img>
                    <img src={img2} className={classes.iconfacility}></img>
                </div>

                <div className={classes.right}>
                    <div className={classes.card}>
                        <p className={classes.head}>QUALITY ASSURANCE</p>
                        <p className={classes.txt}>The company has sound quality assurance team, which makes surveys as well as site inspections for assuring high standard of quality services to be rendered to the client.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>WORKSPACE AWARENESS</p>
                        <p className={classes.txt}>A profession that encompasses multiple disciplines to ensure functionality of the built environment by integrating people, place, process and technology.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>TECHNICIAL PROBLEM SOLVING</p>
                        <p className={classes.txt}>We give mandatory refresher training to all our employees twice a year. More frequent training is provided as per the client's needs and the performance of each specific individual.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>POSITIVE ATTITUDE TOWARDS CLIENTS AND SERVICES</p>
                        <p className={classes.txt}>SECURISE SERVICES holds many sessions to develop a positive attitude in its employees to cope with difficult real-life situations without bitterness or any negativity.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>ENVIRONMENTAL TRAINING</p>
                        <p className={classes.txt}>SECURISE SERVICES also trains its employees to be eco-friendly in their outlook and care for the environment.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FacilityT;