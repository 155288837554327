import classes from './Card3.module.css';

const Card3 = () => {
    return (
        <div className={classes.card}>

            <p className={classes.head}>TRUSTED | SIMPLE | AFFORDABLE</p>
            <p className={classes.head1}>10+ Years Of Experience In Facility</p>
            <p className={classes.head2}> Management Service In Pune</p>

        </div>
    )
}

export default Card3;