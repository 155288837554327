import { useEffect } from 'react';
import classes from './ContactPage.module.css';
import face from '../components/Contact/asset/customer-service.png';
import facebook from '../components/Contact/asset/facebook.png';
import instagram from '../components/Contact/asset/instagram.png';

const ContactPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.hold}>
            <div className={classes.hold2}>
                <p className={classes.head}>Get In Touch</p>

            </div>

            <div className={classes.hold3}>
                <div className={classes.left}>
                    <p className={classes.reach}>Reach Out To Us</p>
                    <p className={classes.reach2}>Got a question about Securise?. Are you interested in partnering with us? Have some suggestions or just want to say hi? Contact us.</p>

                    <div className={classes.formHold}>
                        <form action='https://formsubmit.co/admin@securise.in' method='POST' >
                            <input type='text' name='name' placeholder='Name' className={classes.in}></input><br></br>
                            <input type='text' name='mobile number' placeholder='Mobile Number' className={classes.in}></input><br></br>
                            <input type='text' name='email' placeholder='Email Id' className={classes.in}></input><br></br>
                            <textarea type='text' name='message' placeholder='Message' className={classes.in2}></textarea><br></br>
                            <input type="hidden" name="_captcha" value='false'></input>
                            <button className={classes.btn} type='submit'>Send</button>
                        </form>
                    </div>
                </div>
                <div className={classes.right}>
                    <p className={classes.care}>Customer Care</p>
                    <p className={classes.care2}>Not sure where to start? Need help adding that extra mojo to your landing page? Just get in touch with us.</p>

                    <div className={classes.imgHold}>
                        <img src={face} className={classes.face}></img>
                        <p className={classes.name}>Customer Care</p>
                        <p className={classes.name}>+91 7796004991</p>
                    </div>

                    <p className={classes.ways}>Other ways to connect</p>
                    <div className={classes.otherHold}>

                        <img src={facebook} className={classes.icon}></img>
                        <img src={instagram} className={classes.icon}></img>
                    </div>
                </div>
            </div>

            <div className={classes.mapHold}>
                <p className={classes.locate}>Locate Us:</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7565.0683266940005!2d73.91853563361481!3d18.549941605042374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c165db6cd123%3A0x8937f758e672e889!2sSECURISE%20SERVICES!5e0!3m2!1sen!2sin!4v1694250022878!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">

                </iframe>
            </div>
        </div>
    )
}

export default ContactPage;