import './Feature2.css';
import { Link } from 'react-router-dom';

/*
const [sel, setSel] = useState(true);
    const [sel2, setSel2] = useState(false);
    const [sel3, setSel3] = useState(false);

    const [security, setSecurity] = useState(true);
    const [facility, setFacility] = useState(false);
    const [House, setHouse] = useState(false);

    const Btn1Hnd = () => {
        setSel(true);
        setSel2(false);
        setSel3(false);
        setSecurity(true);
        setFacility(false);
        setHouse(false);
    };

    const Btn2Hnd = () => {
        setSel(false);
        setSel2(true);
        setSel3(false);
        setSecurity(false);
        setFacility(true);
        setHouse(false);
    };

    const Btn3Hnd = () => {
        setSel(false);
        setSel2(false);
        setSel3(true);
        setSecurity(false);
        setFacility(false);
        setHouse(true);
    };


 {security && <div className='SecurityHoldnew'>
                <div className='SecurityCardHold'>
                    <div className='cardNew cad1'><p className='txtNew'>Corporate Security</p></div>
                    <div className='cardNew cad2'><p className='txtNew'>personal Bodyguard</p></div>
                    <div className='cardNew cad3'><p className='txtNew'>Event Security</p></div>
                    <div className='cardNew cad4'><p className='txtNew'>Security Escort</p></div>
                    <div className='cardNew cad5'><p className='txtNew'>Armed Security</p></div>
                    <div className='cardNew cad6'><p className='txtNew'>Lady Security</p></div>
                    <div className='cardNew cad7'><p className='txtNew'>Industrial Security</p></div>
                    <div className='cardNew cad8'><p className='txtNew'>Bouncer Security</p></div>
                    <div className='cardNew cad9'><p className='txtNew'>ATM Service</p></div>
                </div>

            </div>}

            {facility && <div className='SecurityHold2'>
                <div className='SecurityCardHold'>
                    <div className='cardNew cad10'><p className='txtNew'>Integrated Facility Management</p></div>
                    <div className='cardNew cad11'><p className='txtNew'>Waste Management</p></div>
                    <div className='cardNew cad12'><p className='txtNew'>Cleaning Services</p></div>
                    <div className='cardNew cad13'><p className='txtNew'>Mechanised Housekeeping</p></div>
                    <div className='cardNew cad14'><p className='txtNew'>Club House Maintenance & Management</p></div>
                    <div className='cardNew cad15'><p className='txtNew'>Residential & Commercial Services</p></div>

                </div>

            </div>}

             {House && <div className='SecurityHold3'>
                <div className='SecurityCardHold'>
                    <div className='cardNew cad16'><p className='txtNew'>Facade Cleaning</p></div>
                    <div className='cardNew cad17'><p className='txtNew'>Upholstery Cleaning</p></div>
                    <div className='cardNew cad18'><p className='txtNew'>Electrical & Mechanical Maintenance</p></div>
                </div>

            </div>}

             : 'fatureBtn'}
 */

const Feature2 = () => {

    return (
        <div className='holdnew'>
            <p className='fatureHead'>Featured Services</p>
            <p className='fatureHead2'>Securise prides itself in offering customised and high-quality services to our customers.</p>
            <p className='fatureHead2'>Our range of services in Pune includes:</p>

            <p className='quick'>Quick Actions</p>
            <div className='fatureBtnHold  container-fluid'>
                <div className='fatureBtnHoldIn'><Link to='/security'><button className='Btn1 fatureBtn'>Security Services</button></Link></div>
                <div className='fatureBtnHoldIn'><Link to='/FacilityManagement'><button className='Btn1 fatureBtn'>Facility Management</button></Link></div>
                <div className='fatureBtnHoldIn'><Link to='/Housekeeping'><button className='Btn1 fatureBtn'>HouseKeeping Services</button></Link></div>
            </div>


        </div>
    )

}


export default Feature2;