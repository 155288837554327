import classes from './NewSecurity.module.css';
import img3 from './asset/fac11 (1).jpg';
import img4 from './asset/fac12.jpeg';
import { Link } from 'react-router-dom';

const NewMgm = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.hold1}>
                <div className={classes.hold1L}>
                    <p className={classes.txt}>
                        Management of your premises can become more important, and you may find yourself unprepared for sudden problems. SECURISE SERVICE takes stock of potential issues and mitigates them with scheduled maintenance. Our rosters of facility management undergoes rigorous verifications and are chosen to provide the best services with utmost care and compassion .Our intense housekeeping service training programs, superior on-site management, and the respect we show towards our customers and employees create an environment in which we consistently exceed the services needs and goals of our clients. </p>
                </div>
                <div className={classes.hold1R}>
                    <img src={img3} className={classes.img1}></img>
                </div>
            </div>

            <div className={classes.hold1}>
                <div className={classes.hold1L}>
                    <img src={img4} className={classes.img1}></img>
                </div>
                <div className={classes.hold1R}>
                    <p className={classes.txt}>We follow best facility management practices to earn customer satisfaction and thereby customer retention. Our trained staffs ensure that your premises remain spick and span every day by performing efficient and periodic cleaning tasks.</p>
                    <p className={classes.txt}>A market leader of essential service verticals, we have steadily increased our offerings and reach, catering to the varied and dynamic demands of the markets we serve, including marquee clients that comprise Fortune 200 companies.</p>
                    <Link to='/contact' className='linker'><button className={classes.btn}>Contact Us</button></Link>
                </div>
            </div>
        </div>
    )
}

export default NewMgm;