import classes from './NewSecurity.module.css';
import img3 from './asset/hk1.jpeg';
import img4 from './asset/hk2cpy.jpg';
import { Link } from 'react-router-dom';

const NewHouse = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.hold1}>
                <div className={classes.hold1L}>
                    <p className={classes.txt}>
                        Housekeeping of your office can become a more importance, and you may find yourself unprepared for sudden problems. SECURISE SERVICE takes stock of potential issues and mitigates them with scheduled maintenance. Our rosters of housekeepers undergoes rigorous verifications and are chosen to provide the best housekeeping services with utmost care and compassion .Our intense housekeeping service training programs, superior on-site management, and the respect we show towards our customers and employees create an environment in which we consistently exceed the janitorial service needs and goals of our clients. We follow best housekeeping practice to earn customer satisfaction and thereby customer retention</p>
                </div>
                <div className={classes.hold1R}>
                    <img src={img3} className={classes.imgHouse}></img>
                </div>
            </div>

            <div className={classes.hold1}>
                <div className={classes.hold1L}>
                    <img src={img4} className={classes.imgHouse}></img>
                </div>
                <div className={classes.hold1R}>
                    <p className={classes.txt}>Our trained housekeeping staffs ensure that your premises remain spick and span every day by performing efficient and periodic cleaning tasks.</p>
                    <Link to='/contact' className='linker'><button className={classes.btn}>Contact Us</button></Link>
                </div>
            </div>
        </div>
    )
}

export default NewHouse;