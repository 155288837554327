import { useEffect } from 'react';
import classes from './SecurityT.module.css';
import housekeeping from './housekeepingT.png';
import img1 from '../asset/HouseT.jpg';
import img2 from '../asset/HouseT2.jpg';

const HouseT = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.hold}>
            <div className={classes.namehold}>
                <p className={classes.name}>HouseKeeping Training</p>
            </div>
            <div className={classes.divsHold}>
                <div className={classes.lft}>
                    <img src={img1} className={classes.iconHouseT}></img>
                    <img src={img2} className={classes.iconHouseT}></img>
                </div>

                <div className={classes.right}>
                    <div className={classes.card}>
                        <p className={classes.head}>PERSONAL GROOMING</p>
                        <p className={classes.txt}>Grooming achieve two purposes: to protect our staff from the stains and toxins which come from daily cleaning product use, and to give our staff a professional, ‘uniform’ appearance.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}> CLEANING EQUIPMENT</p>
                        <p className={classes.txt}>A profession that encompasses multiple disciplines to ensure functionality of the built environment by integrating people, place, process and technology.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>CHEMICALS KNOWLEDGE</p>
                        <p className={classes.txt}>Our service providers have deep knowledge of chemicals and solutions which they use. This inceases quality of service and also reduces price of service.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>EYE FOR DETAILS</p>
                        <p className={classes.txt}>Although the housekeeping team might not be actually doing the maintenance, it is their job to report issues to maintenance and monitor their repair.</p>
                    </div>

                    <div className={classes.card}>
                        <p className={classes.head}>PROMPT COMMUNICATION</p>
                        <p className={classes.txt}>This may seem like a given, but without following clear communication, both between housekeeping and between the other departments in the house, the premises can suffer. From simple requests through to detailed schedule instructions and last minute changes, the communication will help bond the team and ensure the highest level of care and approach is achieved.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HouseT;