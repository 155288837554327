import { useEffect } from "react";
import classes from './Security.module.css';

const Security = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.hold}>
            <div className={classes.namehold}>
                <p className={classes.name}>Security Services</p>
            </div>



            <div className={classes.cardhold}>
                <div className={classes.card}>
                    <div className={classes.inCard}><p className={classes.txt}>Apartment Security</p></div>
                </div>

                <div className={classes.card2}>
                    <div className={classes.inCard}><p className={classes.txt}>School Security</p></div>
                </div>

                <div className={classes.card3}>
                    <div className={classes.inCard}><p className={classes.txt}>Hospital Security</p></div>
                </div>

                <div className={classes.card4}>
                    <div className={classes.inCard}><p className={classes.txt}>Factory Security</p></div>
                </div>

                <div className={classes.card5}>
                    <div className={classes.inCard}><p className={classes.txt}>Mall Security</p></div>
                </div>

                <div className={classes.card6}>
                    <div className={classes.inCard}><p className={classes.txt}>Residential Security</p></div>
                </div>

                <div className={classes.card7}>
                    <div className={classes.inCard}><p className={classes.txt}>Hotel Security</p></div>
                </div>

                <div className={classes.card8}>
                    <div className={classes.inCard}><p className={classes.txt}>Corporate Security</p></div>
                </div>
            </div>
        </div>
    )
}

export default Security;