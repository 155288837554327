import classes from './Certified.module.css';
import OrgChart from './Organisation.pdf';

const Certified = () => {
    return (
        <div>
            <div className={classes.hold}>
                <p className={classes.head}>We Are ISO Certified Company</p>
                <p className={classes.txt}>We are an ISO certified company and have consistently delighted our customers with exceptional facility management service in all areas – housekeeping, security, facility management etc.
                    Our team of competent employees has been instrumental in helping us reach the heights of success and growth, winning us numerous accolades from our esteemed clients along the way.</p>
                <p className={classes.txt}>Customer requirement has always been our priority and will continue to be so as we continue to delight our customers with the best Top 10 Facility Management Company in Pune.</p>

                <p className={classes.head2}>We Are One Of The Leading Facility Management Agencies!!</p>
                <p className={classes.txt}>We are one of the leading facility management service provider agency in Pune with an unmatched reputation for reliability and excellence. We have a team of highly trained professionals who ensure that you are safe and comfortable at all times. Your satisfaction is our priority!!</p>
                <p className={classes.txt}>In the past so many years, we are the largest organization providing the Best Facility Management Services in Pune. Our team includes more than 1000 of workers that are dedicated to working for your comfort. Securise Services security is leading the market with ISO certified. We assure the safety of our clients by taking care of association and individuals in the best possible way. Our motto is to satisfy our customers from all their trouble and bring assistance to their life.</p>
            </div>

            <div className={classes.hold2}>
                <p className={classes.chart}>
                    <span className={classes.chartColor}>Securise</span> Organisation Chart</p>

                <embed className={classes.pdf} src={OrgChart} width="90%" height="600"
                    type="application/pdf"></embed>
            </div>
        </div>
    )
}

export default Certified;