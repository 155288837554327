import { useEffect } from 'react';
import Vertical from '../components/Home/Slider/Slide';
import Why from '../components/Home/Why/Why';
import Company from '../components/Home/Company/Company';
import Test from '../components/Home/Testimonial/Test';
import Popup from '../components/Popup/Popup';
import Feature2 from '../components/Home/Featured2/Feature2';
import Quality from '../components/Home/Quality/Quality';

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Vertical></Vertical>
            <Company></Company>
            <Popup></Popup>
            <Feature2></Feature2>
            <Why></Why>
            <Quality></Quality>
            <Test></Test>


        </div>
    )
}

export default HomePage;