import { Outlet } from "react-router-dom";
import Navigation from "../components/Navbar/Navi";
import Footer from "../components/Footer/Footer";
import Contact from '../components/Home/Contact/Contact';
import NewWhatsapp from "../components/Whatsapp/Whats";


const Root = () => {
    return <div>

        <Navigation></Navigation>
        <Outlet></Outlet>
        <Contact></Contact>
        <NewWhatsapp></NewWhatsapp>
        <Footer></Footer>


    </div>
};

export default Root;