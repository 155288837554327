import Facility from "./Facility/Facility";
import Key from "./Security/Key";
import NewMgm from "../components/New/NewMgm";

const FacilityPage = () => {
    return (
        <div>
            <Facility></Facility>
            <NewMgm></NewMgm>
            <Key></Key>
        </div>
    )
}

export default FacilityPage;