import classes from './WhyCad.module.css';
import user from '../asset/user-avatar.png';

const WhyCard = (props) => {
    return (
        <div className={classes.WhyCard}>
            <img src={props.image} className={classes.icon}></img>
            <p className={classes.head}>{props.head}</p>
            <p className={classes.txt}>{props.txt}</p>
        </div>
    )
}

export default WhyCard;