import React, { Component } from "react";
import classes from './MySlider.module.css';
import Slider from "react-slick";

import clean from '../asset/clean.webp';
import clean2 from '../asset/clean2.png';

import hk1 from '../asset/new/hk1.jpg';
import hk2 from '../asset/new/hk2.jpg';
import qrt from '../asset/new/qrt1.jpg';
import sec1 from '../asset/new/sec1.jpg';
import dog1 from '../asset/new/dog1.jpg';
import squad1 from '../asset/new/squad1.jpeg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class MySlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,

            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className={classes.hold}>
                <Slider {...settings}>
                    <div>
                        <div className={classes.inDiv1}></div>
                    </div>

                    <div>
                        <div className={classes.inDiv2}></div>

                    </div>

                    <div>
                        <div className={classes.inDiv3}></div>

                    </div>

                    <div>
                        <div className={classes.inDiv4}></div>

                    </div>

                    <div>
                        <div className={classes.inDiv5}></div>

                    </div>

                    <div>
                        <div className={classes.inDiv6}></div>

                    </div>

                    <div>
                        <div className={classes.inDiv7}></div>

                    </div>

                </Slider>
            </div>
        );
    }
}