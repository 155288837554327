import classes from './Test.module.css';
import TestSlider from './TestSlider';
import { Link } from 'react-router-dom';

const Test = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.linehold}>
                <p className={classes.head1}>Client Testimonials</p>
                <hr className={classes.line}></hr>
            </div>

            <div className={classes.head2Hold}>
                <p className={classes.head2}>What Our Clients Say</p>
                <button className={classes.head2Btn}><Link to='/contact' className={classes.lnk}>Contact Us</Link></button>
            </div>
            <TestSlider></TestSlider>
        </div>
    )
}

export default Test;