import classes from "./Mission.module.css";

import mission from "./Images/target.png";
import vision from "./Images/vision.png";
import values from "./Images/diamond.png";

const Mission = () => {
    return (
        <div className={classes.container}>

            <div className={classes.box}>
                <img className={classes.imgvision1} src={mission} alt="" />
                <h4>Our Mission</h4>
                <p>To become a market leader and competent company in Facility Management industry with our three core values:</p>
                <p className={classes.p1}> 1) Serve our clients</p>
                <p className={classes.p1} > 2) Supporting our people</p>
                <p className={classes.p1}> 2) Supporting our people</p>
                <p className={classes.p1}> 3) Deliver strategic, fully integrated Facility</p>
                <p className={classes.p1}>  Management Services and proving exceptional solutions to our clients, real estate owners, Occupiers and investors.</p>
            </div>
            <div className={classes.box}>
                <img className={classes.imgvision} src={vision} alt="" />
                <h4>Our Vision</h4>
                <p className={classes.boxTxt}>To be the chosen Best Facility Management and real estate expert and planning and strategic adviser to the leading industries, clients, owners, occupiers and serve the client with best industry practices and develop a family of 10,000 enthusiasts for SECURISE.</p>
            </div>
            <div className={classes.box}>
                <img className={classes.imgvalues} src={values} alt="" />
                <h4>Our Values</h4>
                <p><b>Team Work:</b> We not me, so we can be our best</p>
                <p className={classes.p1}>  <b>Respect:</b> Be Open and Honest</p>
                <p className={classes.p1}> <b>Integrity:</b> Mean what you say and say what you mean</p>
                <p className={classes.p1}><b>Recognition:</b>  Say Thank you.</p>
                <p className={classes.p1}> <b>Customer Service:</b> Smile</p>
                <p className={classes.p1}><b>Safety:</b> A safe you is a safe me.</p>
            </div>
        </div>



    );
};
export default Mission;