import React, { Component } from "react";
import Slider from "react-slick";
import classes from './Slide.module.css';
import Card from "./Card";
import Card2 from "./Card2";
import Card3 from "./Card3";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default class Vertical extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 4000,
            vertical: true,
            verticalSwiping: true,
            beforeChange: function (currentSlide, nextSlide) {
                console.log("before change", currentSlide, nextSlide);
            },
            afterChange: function (currentSlide) {
                console.log("after change", currentSlide);
            }
        };
        return (<div className={classes.hold}>
            <div>

                <Slider {...settings}>
                    <div>
                        <Card></Card>
                    </div>
                    <div>
                        <Card2></Card2>
                    </div>
                    <div>
                        <Card3></Card3>
                    </div>


                </Slider>
            </div>
        </div>
        );
    }
}