import classes from './NewSecurity.module.css';
import img3 from './asset/sec11 (1).jpg';
import img4 from './asset/sec12.webp';
import { Link } from 'react-router-dom';

const NewSecurity = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.hold1}>
                <div className={classes.hold1L}>
                    <p className={classes.txt}>
                        <span className={classes.txt2}>SECURISE SERVICE </span>foremost security guard service providers that prominently guard the society, area, property, and everything against criminals throughout Pune. Even a peaceful place sometimes becomes violent and disordered due to criminal activities. We have the best security guard agency committed to provide high-level Security services to its client’s properties full of pro-activeness and professionalisms. </p>
                    <p className={classes.txt}>With an unremitting spirit of innovation coupled with a dynamic and multifaceted portfolio of solutions, we strive to offer people enduring value. Our deep market insights of more than 12 years enable us to leverage growth opportunities in the key markets where we operate concurrently, helping our brands emerge as trusted leaders.</p>
                </div>
                <div className={classes.hold1R}>
                    <img src={img3} className={classes.img1}></img>
                </div>
            </div>

            <div className={classes.hold1}>
                <div className={classes.hold1L}>
                    <img src={img4} className={classes.img1}></img>
                </div>
                <div className={classes.hold1R}>
                    <p className={classes.txt}>We provide Armed-Unarmed Security Guards to our clients according to their needs. Our guards are highly talented and have excellent knowledge about the areas and behaviours of different locations. Securise Service hires only those employees who have gone through detailed verification and have experienced guarding knowledge. Even before placing our guards at a specific location, we train them accordingly to ensure the highest security level.</p>
                    <Link to='/contact' className='linker'><button className={classes.btn}>Contact Us</button></Link>
                </div>
            </div>
        </div>
    )
}

export default NewSecurity;