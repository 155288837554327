import { useEffect } from 'react';
import classes from './Facility.module.css';

const Facility = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={classes.hold}>
            <div className={classes.namehold}>
                <div className={classes.inCard}><p className={classes.name}>Facility Management Services</p></div>
            </div>

            <div className={classes.cardhold}>
                <div className={classes.card}>
                    <div className={classes.inCard}><p className={classes.txt}>Integrated Facility Management</p></div>
                </div>

                <div className={classes.card2}>
                    <div className={classes.inCard}><p className={classes.txt}>Technicial Services</p></div>
                </div>

                <div className={classes.card3}>
                    <div className={classes.inCard}><p className={classes.txt}>Asset Management</p></div>
                </div>

                <div className={classes.card4}>
                    <div className={classes.inCard}><p className={classes.txt}>Vendor Management</p></div>
                </div>

                <div className={classes.card5}>
                    <div className={classes.inCard}><p className={classes.txt}>Clubhouse Maintenance & Management</p></div>
                </div>

                <div className={classes.card6}>
                    <div className={classes.inCard}><p className={classes.txt}>Electrical & Mechanical Maintenance</p></div>
                </div>

                <div className={classes.card7}>
                    <div className={classes.inCard}><p className={classes.txt}>Adminstrative Management</p></div>
                </div>
            </div>
        </div>
    )
}

export default Facility;