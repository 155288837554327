import { useEffect } from "react";
import About1 from "../components/About/About1";
import Mission from "../components/About/MissionVision/Mission";
import Certified from "../components/About/Certified";



const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <About1></About1>
            <Mission></Mission>
            <Certified></Certified>


        </div>
    )
}

export default AboutPage;