import classes from './Card.module.css';
//#E80000
import quote from '../asset/quote.png';


const Card = (props) => {
    return (
        <div className={classes.cardHold}>
            <div className={classes.card}>
                <div className={classes.imgHold}>
                    <img src={props.face} className={classes.face}></img>
                </div>
                <p className={classes.name}>{props.name}</p>
                <img src={quote} className={classes.quote}></img>
                <p className={classes.txt}>{props.txt}</p>
            </div>

        </div>
    )
}

export default Card;