import { useEffect } from "react";
import House from "./House/House";
import Key from "./Security/Key";
import NewHouse from "../components/New/NewHouse";

const HousePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <House></House>
            <NewHouse></NewHouse>
            <Key></Key>
        </div>
    )
}

export default HousePage;