import Security from "./Security/Security";
import Key from "./Security/Key";
import NewSecurity from "../components/New/NewSecurity";

const SecurityPage = () => {
    return (
        <div>

            <Security></Security>
            <NewSecurity></NewSecurity>
            <Key></Key>
        </div>
    )
}

export default SecurityPage;