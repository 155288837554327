import classes from './Why.module.css';
import WhyCard from './WhyCard';

import rating from '../asset/rating.png';
import user from '../asset/user-avatar.png';
import reputation from '../asset/reputation.png';
import online from '../asset/online-education.png';
import focus from '../asset/focus.png';
import easy from '../asset/easy.png';
import price from '../asset/price-tag.png';

const Why = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>Why Choose Us</p>
            <div className={classes.hold2}>
                <div className={classes.left}>
                    <p className={classes.pre}>PREMIUM SERVICES</p>
                    <p className={classes.pre1}>
                        Our Services <b>Makes</b> </p>
                    <p className={classes.pre2}><b>Your Living
                        <span className={classes.pre2Color}> Comfortable</span></b></p>

                </div>
                <div className={classes.right}>
                    <WhyCard image={user} head='User Centric'
                        txt='we provide 100% customer satisfaction'></WhyCard>
                    <WhyCard image={online} head='Professional Approach'
                        txt='we follow  high level of competence, knowledge, and ethical behavior.'></WhyCard>
                </div>
            </div>

            <div className={classes.hold3}>
                <WhyCard image={easy} head='We’re easy to work with. '
                    txt='We take the work seriously, but not ourselves. We’re not prickly, precious or pretentious.'></WhyCard>
                <WhyCard image={focus} head=' we focus on results.'
                    txt=" For us, it’s all about what adds value for you and your business. Above all, we want our words to work for you."></WhyCard>
                <WhyCard image={price} head='Fixed prices and no surprises'
                    txt=" With the level of our hard work and time effort, we give you the fixed price as we' ve gone through our many years in the business."></WhyCard>
                <WhyCard image={reputation} head='We guarantee our work'
                    txt="We provide overall handling when you're with us and if your satisfaction level doesn’t match the work, we fix it at no cost."></WhyCard>
            </div>
        </div >
    )
}

export default Why;