import { useState, useEffect } from "react";
import classes from "./Popup.module.css";
import securise from "./Video/securise.mp4";

const Popup = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(myGreeting, 10000);

    }, []);

    const myGreeting = () => {
        setShow(true);
    };

    const closebtn = () => {
        setShow(false);
    }
    return (
        <>
            <div className={classes.container}>
                {show && <div className={classes.pop}>
                    <video controls muted autoPlay  >
                        <source src={securise} />
                    </video>
                    <buttom className={classes.close} onClick={closebtn}>Close</buttom>
                </div>}
            </div>
        </>
    );
};
export default Popup;