import classes from './Company.module.css';
// 
import { Link } from 'react-router-dom';

import MySlider from './MySlider';

const Company = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.left}>
                <MySlider />
            </div>
            <div className={classes.right}>
                <p className={classes.about}>About the Agency</p>
                <p className={classes.head2}>We Have <span className={classes.yrs}>10+ Years</span></p>
                <p className={classes.head3}>Of Experience</p>

                <div className={classes.listHold}>
                    <li className={classes.list}>Have latest technical know-how</li>
                    <li className={classes.list}>Having high retention rate of clients as well as employees</li>
                    <li className={classes.list}>Securise complies with all provisions of Labor Laws</li>
                </div>
                <button className={classes.btn}><Link className={classes.lnk} to='/about' >Read More</Link></button>
            </div>
        </div>
    )
}

export default Company;