import React, { Component } from "react";
import classes from './TestSlider.module.css';
import Slider from "react-slick";
import Card from "./Card";

import ladyFace from '../asset/woman.png';
import men1 from '../../Contact/face.jpg';
import menFace from '../asset/man.png';

export default class TestSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 5000,
            cssEase: "linear",

            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className={classes.hold}>
                <div>

                    <Slider {...settings}>
                        <div>
                            <Card face={ladyFace} name='Himali Lokhande-Singh' txt='Our society has engaged with Securise for house keeping and Security for the past few months. The management is understanding, patient and quick in action. Discipline is good.'></Card>
                        </div>
                        <div>
                            <Card face={menFace} name='Ganesh Lipane' txt='There, FO asked me for Review. I told them to wait for a month let me see hows your guards and housekeeping performing, So finaly i can say I have no problem to rate them for 5 Stars...'></Card>
                        </div>
                        <div>
                            <Card face={menFace} name='Rajsaheb Gaikwad' txt='Looks Like..They have Quick action team....I have called up there QRT because f some problem in our society. They have reached with there back force in like 20 min.'></Card>
                        </div>
                        <div>
                            <Card face={menFace} name='Sachin Gorde' txt='I was worried about cleaning at my office At Talegaon, Ambi MIDC when I have given them contract for cleaning. But after seeing results its was nice services they have given.'></Card>
                        </div>
                        <div>
                            <Card face={menFace} name='Satish Deshpande' txt='I tried there services at My Packed Driking Water plant, (Aquamagic packed Driking Water)....They have real quick response for any problem at my Plant... appreciated...'></Card>
                        </div>
                        <div>
                            <Card face={ladyFace} name='Gurpreet Kaur' txt='They provide amazing service and that to on time. I prefer Securise Services since 4 years.. Very nice experience..'></Card>
                        </div>
                    </Slider>
                </div>
            </div>
        );
    }
}