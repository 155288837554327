
import React, { useState, useEffect } from 'react';
import classes from './Career.module.css';

const Career = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isExperienced, setIsExperienced] = useState(false);

    const handleExperienceChange = (e) => {
        setIsExperienced(e.target.value === 'experienced');
    };

    return (
        <div className={classes.contain}>
            <p className={classes.join}>Join Our Passionate Team</p>
            <p className={classes.join2}>
                Welcome to Securise, where our work culture maintains the perfect balance between work life and personal life.
            </p>
            <div className={classes.hold}>
                <h2>Apply Now</h2>
                <div className={classes.hold2}>
                    <form action='https://formsubmit.co/admin@securise.in' method='POST' enctype="multipart/form-data">
                        <input className={classes.in} type='text' placeholder='Name' required='true' name='name'></input>
                        <br></br>
                        <input className={classes.in} type='email' placeholder='Email' required='true' name='email'></input>
                        <br></br>
                        <input className={classes.in} type='text' placeholder='Mobile Number' required='true' name='contact'></input>
                        <br></br>
                        <label>
                            Are you a Experienced?
                            <input type='radio' name='experience'value='fresher' checked={!isExperienced} onChange={handleExperienceChange} required='true'/>{' '}No
                           
                            <input type='radio'name='experience' value='experienced' checked={isExperienced} onChange={handleExperienceChange} required='true'/>{' '}Yes
                        </label>
                        <br />
                        {isExperienced && (
                            <div className={classes.experience}>
                                <label>Years of Experience</label>
                                <br />
                                <input className={classes.in} type='number' min={0} max={90} placeholder="Enter the Years of Experience you have" name='yearsOfExperience'  required='true'></input>
                                <br></br>

                                <label>Field of Experience</label>
                                <br />
                                <input className={classes.in} type='text' placeholder="Enter the Field in which you had experience " name='fieldOfExperience'  required='true'></input>
                                <br></br>
                            </div>
                        )}
                        <select className={classes.in} required='true' name='position'>
                            <option className={classes.in}>Select Post Applying For</option>
                            <option className={classes.in}>Facility Manager</option>
                            <option>Assistant Facility Manager</option>
                            <option>Housekeeping Supervisor</option>
                            <option>Housekeeping</option>
                            <option>Security In-charge</option>
                            <option>Security Officer</option>
                            <option>Field Officer</option>
                            <option>Night Rounder</option>
                            <option>Supervisor</option>
                            <option>Security Guard</option>
                        </select>
                        <br></br>
                        <input
                            className={classes.in}
                            type='area'
                            placeholder='Your Area like Hadapsar,Dhayari,Ambegaon ,etc...'
                            required='true'
                            name='area'
                        ></input>
                        <br></br>
                        <label>Upload Resume</label>
                        <input className={classes.btn2} type='file' required='true' placeholder='Upload Resume' name='resume'></input>
                        <br></br>
                        <input type="hidden" name="_captcha" value='false'></input>
                        <button className={classes.btn} type='submit'>
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Career;
