import classes from './Key.module.css';
import Card from './Card';

import liasion from '../asset/draft.png';
import operation from '../asset/clock.png';
import training from '../asset/presentation.png';
import management from '../asset/management.png';
import retention from '../asset/turnover.png';
import special from '../asset/avatar.png';
import strength from '../asset/100-percent.png';
import customer from '../asset/customer-care.png';

const Key = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>Our Key Factors</p>

            <div className={classes.cardHold}>
                <Card image={liasion} head='Close Liasion'
                    txt='Maintain close liasion with Police, Health Department and Fire Department'></Card>

                <Card image={operation} head='Operations'
                    txt='Have effective command and control over all operation'></Card>

                <Card image={training} head='Training Cell'
                    txt='Have an up-to-date training cell to inovate and incorporate all modern developments to existing system'></Card>

                <Card image={management} head='Our Management'
                    txt='Our operations are led by a team of experienced managers having expertise in Facility Management Services.'></Card>

                <Card image={retention} head='Minimal Attretion'
                    txt='Our employee retention rate is almost 90%'></Card>

                <Card image={special} head='Our Specialisation'
                    txt='Our teams our specialised in their respective operations and fields.'></Card>

                <Card image={strength} head='Trust'
                    txt='We ensure 100% of sanctioned strength at all facilities. For us clients trust comes first'></Card>

                <Card image={customer} head='Client Retention'
                    txt='Our client retention rate is above 95%'></Card>


            </div>

        </div >
    )
}

export default Key;