import classes from './Card2.module.css';

const Card2 = () => {
    return (
        <div className={classes.card}>

            <p className={classes.head}>TRUSTED | SIMPLE | AFFORDABLE</p>
            <p className={classes.head1}>10+ Years Of Experience In Housekeeping</p>
            <p className={classes.head2}> Service In Pune</p>

        </div>
    )
}

export default Card2;