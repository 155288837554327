import classes from './Promise.module.css';

const Promiseee = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head1}>Our Promise And Values</p>
            <p className={classes.head2}>Written By Company CEO</p>
            <div className={classes.head3Hold}>
                <p className={classes.head3}>Provide a high standard of security that fully satisfies the needs and aspirations of our clients. Deliver a safe, welcoming and motivational environment for our clients and their staff. Retain our proven image within the industry and maintain a good relationship with our clients.</p>
            </div>
        </div>
    )
}

export default Promiseee;